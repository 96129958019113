var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bono-regalo mx-2"},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v("Bono regalo")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-card',{staticClass:"mx-0",attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_vm._v("Bonos Activos: "+_vm._s(_vm.pagination_table_a.total))]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading_t,"items":_vm.lista_bonos,"dense":"","hide-default-footer":"","page":_vm.pagination_table_a.current_page,"items-per-page":_vm.pagination_table_a.per_page},on:{"update:page":function($event){return _vm.$set(_vm.pagination_table_a, "current_page", $event)},"page-count":function($event){return _vm.countPageA($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('div',{staticStyle:{"width":"300px !important"}},[_c('v-text-field',{staticClass:"mt-6",attrs:{"outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","color":"black"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" Crear ")]),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Registrar bono regalo ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-text',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-autocomplete',_vm._g(_vm._b({ref:"clientes",attrs:{"dense":"","outlined":"","rounded":"","loading":_vm.isLoading,"item-value":"id","items":_vm.lista_clientes,"no-filter":"","label":"Selecciona un cliente","hide-no-data":""},on:{"update:search-input":_vm.customFilter,"change":function($event){return _vm.detailCliente()}},model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}},'v-autocomplete',attrs,false),on))]}}])},[_c('span',[_vm._v("Filtra tus clientes por nombre o cedula / ID")])]),_c('strong',{staticClass:"text-h5 font-weight-medium"},[_vm._v("Cliente")]),(_vm.cliente)?_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',[_c('strong',{staticStyle:{"color":"#1976d2 !important"}},[_vm._v(" "+_vm._s(_vm.cliente.nombre)+" ")]),_c('br'),_c('strong',[_vm._v("Cc: "),_c('span',{staticStyle:{"color":"#1976d2 !important"}},[_vm._v(_vm._s(_vm.cliente.cedula))])]),_c('br'),_c('strong',[_vm._v("Tel: "),_c('span',{staticStyle:{"color":"#1976d2 !important"}},[_vm._v(_vm._s(_vm.cliente.telefono))])]),_c('br'),_c('strong',[_vm._v("Puntos: "),_c('span',{staticStyle:{"color":"#1976d2 !important"}},[_vm._v(_vm._s(_vm.cliente.puntos))])])]),_c('v-col',{attrs:{"align":"right"}},[_c('v-avatar',[_c('img',{attrs:{"src":_vm.$store.state.centro.route +
                                                  'clientes/' +
                                                  _vm.cliente.foto}})])],1)],1):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                  var on = ref.on;
                                                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","rounded":"","dense":"","label":"Fecha","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.bono.fecha),callback:function ($$v) {_vm.$set(_vm.bono, "fecha", $$v)},expression:"bono.fecha"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es-co","color":"primary","first-day-of-week":"1"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.bono.fecha),callback:function ($$v) {_vm.$set(_vm.bono, "fecha", $$v)},expression:"bono.fecha"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"type":"number","label":("Valor " + (_vm.$options.filters.currency(
                                        _vm.bono.valor
                                      )))},on:{"change":function($event){return _vm.setValue('valor')}},model:{value:(_vm.bono.valor),callback:function ($$v) {_vm.$set(_vm.bono, "valor", _vm._n($$v))},expression:"bono.valor"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{attrs:{"rows":"3","no-resize":"","outlined":"","counter":"","auto-grow":"","label":"Observación"},model:{value:(_vm.bono.observacion),callback:function ($$v) {_vm.$set(_vm.bono, "observacion", $$v)},expression:"bono.observacion"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{attrs:{"type":"number","label":("Efectivo " + (_vm.$options.filters.currency(
                                        _vm.bono.efectivo
                                      )))},on:{"change":function($event){return _vm.setValue('efectivo')}},model:{value:(_vm.bono.efectivo),callback:function ($$v) {_vm.$set(_vm.bono, "efectivo", _vm._n($$v))},expression:"bono.efectivo"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{attrs:{"type":"number","label":("Transferencia " + (_vm.$options.filters.currency(
                                        _vm.bono.transfer
                                      )))},on:{"change":function($event){return _vm.setValue('transfer')}},model:{value:(_vm.bono.transfer),callback:function ($$v) {_vm.$set(_vm.bono, "transfer", _vm._n($$v))},expression:"bono.transfer"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{attrs:{"type":"number","label":("Datafono " + (_vm.$options.filters.currency(
                                        _vm.bono.datafono
                                      )))},on:{"change":function($event){return _vm.setValue('datafono')}},model:{value:(_vm.bono.datafono),callback:function ($$v) {_vm.$set(_vm.bono, "datafono", _vm._n($$v))},expression:"bono.datafono"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('h3',[_vm._v(" Total: "+_vm._s(_vm._f("currency")(_vm.totalizacion))+" ")])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"dark":!_vm.loading,"color":!_vm.loading ? 'black' : 'gray',"loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.crear()}}},[_vm._v(" Crear ")])],1)],1)],1)],1)]},proxy:true},{key:"item.obs",fn:function(ref){
                                      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                      var on = ref.on;
                                      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"100px"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.obs)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.obs))])])]}},{key:"item.valor",fn:function(ref){
                                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.valor))+" ")]}},{key:"item.saldo",fn:function(ref){
                                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.saldo))+" ")]}},{key:"item.print",fn:function(ref){
                                      var item = ref.item;
return [_c('router-link',{attrs:{"to":'/print_bono/' + item.id,"target":"_blank"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                      var on = ref.on;
                                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}],null,true)},[_c('span',[_vm._v("Imprimir")])])],1)]}},{key:"item.delete",fn:function(ref){
                                      var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                      var menu = ref.on;
                                      var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                      var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":"","small":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-cancel")])],1)]}}],null,true)},[_c('span',[_vm._v("Anular")])])]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v("Anular")]),_c('v-card-text',[_c('div',{staticStyle:{"font-size":"1.3em"}},[_vm._v(" ¿Estas seguro de anular el bono "),_c('strong',[_vm._v(" "+_vm._s(item.codigo)+"? ")])])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"dark":"","color":"black"},on:{"click":function($event){return _vm.anular(item.id)}}},[_vm._v("Anular")])],1)],1)],1)]}}])}),_c('v-pagination',{attrs:{"length":_vm.lengthPaginationA,"total-visible":7},on:{"input":_vm.changePageA},model:{value:(_vm.page_a),callback:function ($$v) {_vm.page_a=$$v},expression:"page_a"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-card',{staticClass:"mx-0",attrs:{"elevation":"0","outlined":""}},[_c('v-card-title',[_vm._v("Bonos Inactivos: "+_vm._s(_vm.pagination_table_i.total))]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers_in,"loading":_vm.loading_t,"items":_vm.lista_bonos_inactivos,"dense":"","hide-default-footer":"","page":_vm.pagination_table_i.current_page,"items-per-page":_vm.pagination_table_i.per_page},on:{"update:page":function($event){return _vm.$set(_vm.pagination_table_i, "current_page", $event)},"page-count":function($event){return _vm.countPageI($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('div',{staticStyle:{"width":"300px !important"}},[_c('v-text-field',{staticClass:"mt-6",attrs:{"outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search_in),callback:function ($$v) {_vm.search_in=$$v},expression:"search_in"}})],1)])]},proxy:true},{key:"item.obs",fn:function(ref){
                                      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                      var on = ref.on;
                                      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"100px"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.obs)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.obs))])])]}},{key:"item.valor",fn:function(ref){
                                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.valor))+" ")]}},{key:"item.saldo",fn:function(ref){
                                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.saldo))+" ")]}},{key:"item.estado",fn:function(ref){
                                      var item = ref.item;
return [_c('v-chip',{staticClass:"my-2",attrs:{"color":item.estado == 1 ? 'primary' : 'error'}},[(item.estado == 1)?_c('span',[_vm._v("Usado")]):_c('span',[_vm._v("Anulado")])])]}}])}),_c('v-pagination',{attrs:{"length":_vm.lengthPaginationI,"total-visible":7},on:{"input":_vm.changePageI},model:{value:(_vm.page_i),callback:function ($$v) {_vm.page_i=$$v},expression:"page_i"}})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }