<template>
  <div class="bono-regalo mx-2">
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Bono regalo</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="12" sm="12">
                <v-card elevation="0" outlined class="mx-0">
                  <v-card-title
                    >Bonos Activos: {{ pagination_table_a.total }}</v-card-title
                  >
                  <v-card-text>
                    <v-data-table
                      :headers="headers"
                      :loading="loading_t"
                      :items="lista_bonos"
                      dense
                      hide-default-footer
                      :page.sync="pagination_table_a.current_page"
                      :items-per-page="pagination_table_a.per_page"
                      @page-count="countPageA($event)"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <div style="width: 300px !important">
                            <v-text-field
                              outlined
                              rounded
                              dense
                              append-icon="mdi-magnify"
                              label="Buscar"
                              class="mt-6"
                              v-model="search"
                            ></v-text-field>
                          </div>
                          <v-spacer></v-spacer>
                          <v-btn dark color="black" @click="dialog = true">
                            Crear
                          </v-btn>
                          <v-dialog
                            v-model="dialog"
                            persistent
                            scrollable
                            max-width="600px"
                          >
                            <v-card>
                              <v-card-title>
                                Registrar bono regalo
                              </v-card-title>
                              <v-card-text>
                                <v-row dense>
                                  <v-col cols="12" md="12" sm="12">
                                    <v-card elevation="0" outlined>
                                      <v-card-text>
                                        <v-tooltip top>
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-autocomplete
                                              v-model="id"
                                              v-on="on"
                                              v-bind="attrs"
                                              dense
                                              outlined
                                              rounded
                                              :loading="isLoading"
                                              item-value="id"
                                              :items="lista_clientes"
                                              no-filter
                                              label="Selecciona un cliente"
                                              ref="clientes"
                                              hide-no-data
                                              @update:search-input="
                                                customFilter
                                              "
                                              @change="detailCliente()"
                                            ></v-autocomplete>
                                          </template>
                                          <span
                                            >Filtra tus clientes por nombre o
                                            cedula / ID</span
                                          >
                                        </v-tooltip>
                                        <strong
                                          class="text-h5 font-weight-medium"
                                          >Cliente</strong
                                        ><v-row
                                          align="center"
                                          no-gutters
                                          v-if="cliente"
                                        >
                                          <v-col>
                                            <strong
                                              style="color: #1976d2 !important"
                                            >
                                              {{ cliente.nombre }}
                                            </strong>
                                            <br />
                                            <strong
                                              >Cc:
                                              <span
                                                style="color: #1976d2 !important"
                                                >{{ cliente.cedula }}</span
                                              >
                                            </strong>
                                            <br />
                                            <strong
                                              >Tel:
                                              <span
                                                style="color: #1976d2 !important"
                                                >{{ cliente.telefono }}</span
                                              >
                                            </strong>
                                            <br />
                                            <strong
                                              >Puntos:
                                              <span
                                                style="color: #1976d2 !important"
                                                >{{ cliente.puntos }}</span
                                              >
                                            </strong>
                                          </v-col>
                                          <v-col align="right">
                                            <v-avatar>
                                              <img
                                                :src="
                                                  $store.state.centro.route +
                                                    'clientes/' +
                                                    cliente.foto
                                                "
                                              />
                                            </v-avatar>
                                          </v-col>
                                        </v-row>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                  <v-col cols="12" md="6" sm="12">
                                    <v-menu
                                      v-model="menu"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="290px"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
                                          v-model="bono.fecha"
                                          outlined
                                          rounded
                                          dense
                                          label="Fecha"
                                          append-icon="mdi-calendar"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        scrollable
                                        v-model="bono.fecha"
                                        locale="es-co"
                                        color="primary"
                                        first-day-of-week="1"
                                        @input="menu = false"
                                      ></v-date-picker>
                                    </v-menu>
                                  </v-col>
                                  <v-col cols="12" md="6" sm="12">
                                    <v-text-field
                                      type="number"
                                      :label="
                                        `Valor ${$options.filters.currency(
                                          bono.valor
                                        )}`
                                      "
                                      v-model.number="bono.valor"
                                      @change="setValue('valor')"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="12" sm="12">
                                    <v-textarea
                                      rows="3"
                                      no-resize
                                      outlined
                                      counter
                                      auto-grow
                                      v-model="bono.observacion"
                                      label="Observación"
                                    ></v-textarea>
                                  </v-col>
                                  <v-col cols="12" md="4" sm="12">
                                    <v-text-field
                                      type="number"
                                      :label="
                                        `Efectivo ${$options.filters.currency(
                                          bono.efectivo
                                        )}`
                                      "
                                      v-model.number="bono.efectivo"
                                      @change="setValue('efectivo')"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="4" sm="12">
                                    <v-text-field
                                      type="number"
                                      :label="
                                        `Transferencia ${$options.filters.currency(
                                          bono.transfer
                                        )}`
                                      "
                                      v-model.number="bono.transfer"
                                      @change="setValue('transfer')"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="4" sm="12">
                                    <v-text-field
                                      type="number"
                                      :label="
                                        `Datafono ${$options.filters.currency(
                                          bono.datafono
                                        )}`
                                      "
                                      v-model.number="bono.datafono"
                                      @change="setValue('datafono')"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="12" sm="12">
                                    <h3>
                                      Total: {{ totalizacion | currency }}
                                    </h3>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text @click="cancel()" color="primary">
                                  Cancelar
                                </v-btn>
                                <v-btn
                                  :dark="!loading"
                                  :color="!loading ? 'black' : 'gray'"
                                  :loading="loading"
                                  :disabled="loading"
                                  @click="crear()"
                                >
                                  Crear
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.obs="{ item }">
                        <v-tooltip top max-width="200">
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-on="on"
                              v-bind="attrs"
                              class="d-inline-block text-truncate"
                              style="max-width: 100px;"
                            >
                              {{ item.obs }}
                            </span>
                          </template>
                          <span>{{ item.obs }}</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:item.valor="{ item }">
                        {{ item.valor | currency }}
                      </template>
                      <template v-slot:item.saldo="{ item }">
                        {{ item.saldo | currency }}
                      </template>
                      <template v-slot:item.print="{ item }">
                        <router-link
                          :to="'/print_bono/' + item.id"
                          target="_blank"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="primary"
                                icon
                                small
                                v-on="on"
                                v-bind="attrs"
                              >
                                <v-icon>mdi-printer</v-icon>
                              </v-btn>
                            </template>
                            <span>Imprimir</span>
                          </v-tooltip>
                        </router-link>
                      </template>
                      <template v-slot:item.delete="{ item }">
                        <v-menu
                          :close-on-content-click="true"
                          :nudge-width="200"
                          offset-x
                        >
                          <template v-slot:activator="{ on: menu, attrs }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  color="error"
                                  icon
                                  small
                                  v-on="{ ...tooltip, ...menu }"
                                  v-bind="attrs"
                                >
                                  <v-icon>mdi-cancel</v-icon>
                                </v-btn>
                              </template>
                              <span>Anular</span>
                            </v-tooltip>
                          </template>
                          <v-card>
                            <v-card-title>Anular</v-card-title>
                            <v-card-text>
                              <div style="font-size: 1.3em">
                                ¿Estas seguro de anular el bono
                                <strong> {{ item.codigo }}? </strong>
                              </div>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn text color="primary">Cancelar</v-btn>
                              <v-btn dark color="black" @click="anular(item.id)"
                                >Anular</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                      </template>
                    </v-data-table>
                    <v-pagination
                      v-model="page_a"
                      :length="lengthPaginationA"
                      :total-visible="7"
                      @input="changePageA"
                    ></v-pagination>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-card elevation="0" outlined class="mx-0">
                  <v-card-title
                    >Bonos Inactivos:
                    {{ pagination_table_i.total }}</v-card-title
                  >
                  <v-card-text>
                    <v-data-table
                      :headers="headers_in"
                      :loading="loading_t"
                      :items="lista_bonos_inactivos"
                      dense
                      hide-default-footer
                      :page.sync="pagination_table_i.current_page"
                      :items-per-page="pagination_table_i.per_page"
                      @page-count="countPageI($event)"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <div style="width: 300px !important">
                            <v-text-field
                              outlined
                              rounded
                              dense
                              append-icon="mdi-magnify"
                              label="Buscar"
                              class="mt-6"
                              v-model="search_in"
                            ></v-text-field>
                          </div>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.obs="{ item }">
                        <v-tooltip top max-width="200">
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-on="on"
                              v-bind="attrs"
                              class="d-inline-block text-truncate"
                              style="max-width: 100px;"
                            >
                              {{ item.obs }}
                            </span>
                          </template>
                          <span>{{ item.obs }}</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:item.valor="{ item }">
                        {{ item.valor | currency }}
                      </template>
                      <template v-slot:item.saldo="{ item }">
                        {{ item.saldo | currency }}
                      </template>
                      <template v-slot:item.estado="{ item }">
                        <v-chip
                          class="my-2"
                          :color="item.estado == 1 ? 'primary' : 'error'"
                        >
                          <span v-if="item.estado == 1">Usado</span>
                          <span v-else>Anulado</span>
                        </v-chip>
                      </template>
                    </v-data-table>
                    <v-pagination
                      v-model="page_i"
                      :length="lengthPaginationI"
                      :total-visible="7"
                      @input="changePageI"
                    ></v-pagination>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
import formComponent from "../../components/Form.vue";
export default {
  name: "bonoRegalo",
  components: {
    formComponent,
  },
  data: () => ({
    search_c: "",
    id: null,
    menu: false,
    // Pagination
    page_a: 1,
    page_i: 1,
    pagination_table_a: {
      current_page: 1,
      last_page: 0,
      next_page: 1,
      per_page: 25,
      total: 0,
    },
    pagination_table_i: {
      current_page: 1,
      last_page: 0,
      next_page: 1,
      per_page: 25,
      total: 0,
    },
    loading: false,
    loading_t: false,
    error: [],
    search: "",
    search_in: "",
    dialog: false,
    isLoading: false,
    clientes: [],
    cliente: null,
    bonos: {
      activos: [],
      inactivos: [],
    },
    headers: [
      {
        text: "Código",
        value: "codigo",
        align: "start",
        width: 90,
      },
      {
        text: "Fecha",
        value: "fecha",
        width: 110,
      },
      {
        text: "Cliente",
        value: "cliente",
        width: 110,
      },
      {
        text: "ID/Cc",
        value: "cedula",
      },
      {
        text: "Observación",
        value: "obs",
        sortable: false,
      },
      {
        text: "Valor",
        value: "valor",
      },
      {
        text: "Saldo",
        value: "saldo",
      },
      // {
      //   text: "Estado",
      //   value: "estado",
      // },
      {
        text: "",
        value: "print",
      },
      {
        text: "",
        value: "delete",
      },
      // {
      //   text: "Acciones",
      //   value: "acciones",
      // },
    ],
    headers_in: [
      {
        text: "Código",
        value: "codigo",
        align: "start",
        width: 90,
      },
      {
        text: "Fecha",
        value: "fecha",
        width: 110,
      },
      {
        text: "Cliente",
        value: "cliente",
        width: 110,
      },
      {
        text: "ID/Cc",
        value: "cedula",
      },
      {
        text: "Observación",
        value: "obs",
        sortable: false,
      },
      {
        text: "Valor",
        value: "valor",
      },
      {
        text: "Estado",
        value: "estado",
      },
    ],
    bono: {
      valor: 0,
      observacion: "",
      efectivo: 0,
      transfer: 0,
      datafono: 0,
      fecha: moment().format("YYYY-MM-DD"),
    },
  }),
  methods: {
    loadBonosA() {
      this.loading_t = true;
      const body = {
        route: `/bonos?page=${this.pagination_table_a.current_page}`,
        params: {
          perPage: this.pagination_table_a.per_page,
          search: this.search,
          estado: 0,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.pagination_table_a.total = response.data.data.total;
            this.pagination_table_a.last_page = response.data.data.last_page;
            this.pagination_table_a.from = response.data.data.from;
            this.pagination_table_a.to = response.data.data.to;
            this.bonos.activos = response.data.data.data;
          }
        })
        .finally(() => (this.loading_t = false));
    },
    loadBonosI() {
      this.loading_t = true;
      const body = {
        route: `/bonos?page=${this.pagination_table_i.current_page}`,
        params: {
          perPage: this.pagination_table_i.per_page,
          search: this.search_in,
          estado: 2,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.pagination_table_i.total = response.data.data.total;
            this.pagination_table_i.last_page = response.data.data.last_page;
            this.pagination_table_i.from = response.data.data.from;
            this.pagination_table_i.to = response.data.data.to;
            this.bonos.inactivos = response.data.data.data;
          }
        })
        .finally(() => (this.loading_t = false));
    },
    loadClientesLite() {
      this.clientes = [];
      this.isLoading = true;
      const body = {
        route: `/clientes_lite`,
        params: {
          search: this.search_c,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.clientes = response.data.data;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    detailCliente() {
      const body = {
        route: "/detalle_cliente",
        params: {
          id: this.id,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.cliente = response.data.data;
          }
        })
        .finally(() => {
          this.$refs.clientes.reset();
        });
    },
    customFilter(item) {
      this.search_c = item;
      if (this.search_c != null || this.search_c != undefined) {
        if (this.search_c != "") this.loadClientesLite();
      }
    },
    countPageA(value) {
      this.pagination_table_a.current_page = value == 0 ? 1 : value;
    },
    countPageI(value) {
      this.pagination_table_i.current_page = value == 0 ? 1 : value;
    },
    changePageA(data) {
      this.pagination_table_a.current_page = data;
      this.loadBonosA();
    },
    changePageI(data) {
      this.pagination_table_i.current_page = data;
      this.loadBonosI();
    },
    setValue(value) {
      this.bono[value] = this.bono[value] == "" ? 0 : this.bono[value];
    },
    crear() {
      this.loading = true;

      if (this.cliente) {
        if (this.bono.valor <= this.totalizacion) {
          let data = this.bono;
          data = Object.assign(
            {
              cliente_id: this.cliente.id,
              cliente: this.cliente.nombre,
              cc_cliente: this.cliente.cedula,
            },
            data
          );
          const body = {
            route: "/register_bono",
            data: {
              bono: data,
            },
          };
          this.$store
            .dispatch("axios_post", body)
            .then((response) => {
              if (response.data.success) {
                let alert = {
                  alert: true,
                  color: "success",
                  text: response.data.data,
                };
                this.$store.dispatch("setAlert", alert);
                this.loadBonosA();
                this.cancel();
                this.pagination.current_page = 1;
                this.loadClientesLite();
              }
            })
            .catch((error) => {
              let alert = {
                alert: true,
                color: "error",
                text: error.response.data.data,
              };
              this.$store.dispatch("setAlert", alert);
            })
            .finally(() => (this.loading = false));
        } else {
          let alert = {
            alert: true,
            color: "error",
            text:
              "La distribución en métodos de pago no puede ser menor al valor del bono",
          };
          this.$store.dispatch("setAlert", alert);
          this.loading = false;
        }
      } else {
        let alert = {
          alert: true,
          color: "error",
          text: "Debes seleccionar un cliente",
        };
        this.$store.dispatch("setAlert", alert);
        this.loading = false;
      }
    },
    anular(id) {
      const body = {
        route: "/anular_bono",
        data: {
          id,
        },
      };
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            this.loadBonosA();
            this.loadBonosI();
          }
        })
        .catch((error) => {
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        });
    },
    cancel() {
      this.loading = false;
      this.dialog = false;
      this.cliente = null;
      this.setForm();
    },
    setForm() {
      this.bono = {
        valor: 0,
        observacion: "",
        efectivo: 0,
        transfer: 0,
        datafono: 0,
        fecha: moment().format("YYYY-MM-DD"),
      };
    },
  },
  created() {
    this.loadBonosA();
    this.loadBonosI();
    // this.loadClientesLite();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["recepcion"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    lista_clientes() {
      return this.clientes.map((cliente) => {
        return {
          text: `${cliente.nombre} [${cliente.cedula}]`,
          // nombre: cliente.nombre,
          // cedula: cliente.cedula,
          id: cliente.id,
        };
      });
    },
    lista_bonos() {
      return this.bonos.activos.map((b) => {
        return {
          id: b.id,
          codigo: b.codigo,
          fecha: moment(b.fecha).format("ll"),
          cliente: b.cliente,
          cedula: b.cc_cliente,
          obs: b.observacion,
          valor: b.valor,
          saldo: parseFloat(b.valor) - parseFloat(b.saldo),
        };
      });
    },
    lista_bonos_inactivos() {
      return this.bonos.inactivos.map((b) => {
        return {
          id: b.id,
          codigo: b.codigo,
          fecha: moment(b.fecha).format("ll"),
          cliente: b.cliente,
          cedula: b.cc_cliente,
          obs: b.observacion,
          valor: b.valor,
          estado: b.estado,
        };
      });
    },
    totalizacion() {
      return (
        parseFloat(this.bono.efectivo) +
        parseFloat(this.bono.transfer) +
        parseFloat(this.bono.datafono)
      );
    },
    lengthPaginationA() {
      function is_decimal(val) {
        if (val % 1 == 0) {
          return true;
        } else {
          return false;
        }
      }
      let number =
        this.pagination_table_a.total / this.pagination_table_a.per_page;

      if (!is_decimal(number)) number = parseInt(number) + 1;

      return number;
    },
    lengthPaginationI() {
      function is_decimal(val) {
        if (val % 1 == 0) {
          return true;
        } else {
          return false;
        }
      }
      let number =
        this.pagination_table_i.total / this.pagination_table_i.per_page;

      if (!is_decimal(number)) number = parseInt(number) + 1;

      return number;
    },
  },
  watch: {
    id(val) {
      if (val != undefined || val != null) this.$refs.clientes.reset();
      return;
    },
    cliente(val) {
      this.error = [];
      return this.cliente;
    },
    search(val) {
      this.pagination_table_a.current_page = 1;
      this.page_a = 1;
      this.loadBonosA();
      return;
    },
    search_in(val) {
      this.pagination_table_i.current_page = 1;
      this.page_i = 1;
      this.loadBonosI();
      return;
    },
  },
};
</script>
